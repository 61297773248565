import {
  faMagnifyingGlass,
  faLocationDot,
  faTint,
  faWind,
} from '@fortawesome/free-solid-svg-icons';

export default {
  faMagnifyingGlass,
  faLocationDot,
  faTint,
  faWind,
};
